import React from 'react';
import { CommonElementsProps } from '../types';

type InputProps = JSX.IntrinsicElements['input'] &
  CommonElementsProps & {
    title?: string;
    isError?: boolean;
    errorMessage?: string;
  };

export function ControlledInput({
  scale = 'medium',
  title,
  className = '',
  onChange,
  isError,
  errorMessage,
  disabled = false,
  ...rest
}: InputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <div className={`text-primary-text ${className}`}>
      {title ? (
        <div className={`select-none  ${isError ? 'text-error' : 'text-secondary-text'}`}>
          {title}
        </div>
      ) : null}
      <input
        className={getInputStyles(scale, isError ?? false, disabled) + ' ' + className}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      />
      <div className="h-4 text-error">{isError ? errorMessage : null}</div>
    </div>
  );
}

function getInputStyles(scale: InputProps['scale'], isError: boolean, disabled: boolean) {
  let styles = 'duration-300 rounded focus-visible:outline-auto ';

  switch (scale) {
    case 'small':
      styles += 'px-2 py-1.5 text-sm ';
      break;
    case 'medium':
      styles += 'px-4 py-2.5 text-base ';
      break;
    case 'large':
      styles += 'px-6 py-3.5 text-lg font-semibold ';
      break;
  }

  if (!isError) {
    styles += 'border border-darkGray border-opacity-40 ';
  } else {
    styles += 'border border-error ';
  }

  if (disabled) styles += 'opacity-50 ';

  return styles;
}
