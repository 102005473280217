import React, { FC } from 'react';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues, validationSchema } from './form';
import { useCreateRespondentsListMutation } from '../../../../api/queries/respondents';
import { Select } from '../../../../components/Select';
import { filterFunction, typeVariants } from './const';
import { FilePicker } from '../../../../components/FilePicker';
import { useModal } from '../../../../lib';
import { InstructionModal } from '../InstructionModal/InstructionModal';
import { respondentsListTypeMap } from '../../../../modules/respondent-list/const';

interface EditResearchModalProps {
  researchId: string;
  modalOpened: boolean;
  onClose: () => void;
}

export const CreateRespondentsListModal: FC<EditResearchModalProps> = ({
  researchId,
  modalOpened,
  onClose,
}) => {
  const {
    modalOpened: instructionModalOpened,
    closeModal: closeInstructionModal,
    openModal: openInstructionModal,
  } = useModal();

  const { mutateAsync, isLoading } = useCreateRespondentsListMutation();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, handleSubmit } = form;

  const onSubmit = handleSubmit(async ({ name, type, file }) => {
    console.log(name, type, file);
    await mutateAsync({
      Name: name,
      Type: type.type,
      ListFile: file,
      ResearchId: researchId,
    });
    reset();
    onClose();
  });

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <>
          <div>
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <div className="font-semibold text-3xl mb-4">Создание списка респондентов</div>
                <div className="mb-2">
                  <Input title="Название списка" className="w-full" name="name" control={control} />
                </div>
                <div className="mb-2">
                  <Select
                    title="Тип списка"
                    name="type"
                    control={control}
                    items={typeVariants}
                    itemToString={(item) => respondentsListTypeMap[item.type]}
                    filterFunction={filterFunction}
                  />
                </div>
                <div className="mb-2">
                  <FilePicker title="CSV файл" name="file" />
                </div>
                <div
                  onClick={openInstructionModal}
                  className="cursor-pointer text-secondary underline underline-offset-4"
                >
                  Как отформатировать файл для загрзуки?
                </div>
                <div className="mt-8 flex justify-end gap-2">
                  <Button onClick={onClose}>Отменить</Button>
                  <Button type="submit" disabled={isLoading}>
                    Создать
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
          <InstructionModal modalOpened={instructionModalOpened} onClose={closeInstructionModal} />
        </>
      }
    />
  );
};
