import 'dayjs/locale/ru';
import React from 'react';
import dayjs from 'dayjs';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './modules/router';
import { queryClient } from './modules/query-client';
import { NotificationsProvider } from './modules/notifications';

dayjs.locale('ru');

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        <BrowserRouter>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </BrowserRouter>
      </NotificationsProvider>
    </QueryClientProvider>
  );
};

export default App;
