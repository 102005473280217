import { mixed, object, string } from 'yup';
import { ListTypeVariant } from './const';

export type FormValues = {
  name: string;
  type: ListTypeVariant;
  file: File;
};

export const validationSchema = object().shape({
  name: string().required('Заполните имя'),
  type: object()
    .shape({ id: string(), type: string() })
    .default(undefined)
    .required('Выберите тип'),
  file: mixed().required('Загрузите файл'),
});
