/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IndexApplicationAuthenticationCommonAuthorizationResult {
  user?: IndexDomainEntitiesUser;
  token?: string | null;
}

export interface IndexApplicationFormsCommandsSendAnswersSendAnswersCommand {
  token?: string | null;
  answers?: string[] | null;
}

export interface IndexApplicationFormsQueriesGetFormGetFormResult {
  researchName?: string | null;
  researchPurpose?: string | null;
  researchQuestions?: string[] | null;
}

export interface IndexApplicationFormsQueriesGetFormResultGetFormResultResponse {
  researchName?: string | null;
  researchId?: string | null;
  respondentsListName?: string | null;
  respondentsListId?: string | null;
  /** @format int32 */
  respondentsCount?: number;
  /** @format int64 */
  formDate?: number;
  /** @format int32 */
  repliesCount?: number;
  questions?: string[] | null;
  answers?: string[][] | null;
}

export interface IndexApplicationResearchesCommandsCloseResearchCloseResearchResult {
  research?: IndexDomainEntitiesResearch;
}

export interface IndexApplicationResearchesQueriesGetUserResearchesGetResearchResult {
  research?: IndexDomainEntitiesResearch;
}

export interface IndexApplicationResearchesQueriesGetUserResearchesGetUserResearchesResult {
  researches?: IndexDomainEntitiesResearch[] | null;
}

export interface IndexApplicationRespondentsListsCommandsCreateRespondentsListCreateRespondentsListResult {
  list?: IndexDomainEntitiesRespondentsList;
}

export interface IndexApplicationRespondentsListsQueriesGetRespondentsListsGetRespondentsListsResult {
  respondentsLists?: IndexDomainEntitiesRespondentsList[] | null;
}

export interface IndexContractsAuthorizationAuthorizeRequest {
  email?: string | null;
  password?: string | null;
}

export interface IndexContractsAuthorizationRegisterRequest {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
  password?: string | null;
  companyName?: string | null;
}

export interface IndexContractsFormsCreateFormRequest {
  researchId?: string | null;
  respondentsListId?: string | null;
  /** @format int32 */
  respondentsCount?: number;
}

export interface IndexContractsFormsCreateFormResponse {
  form?: IndexDomainEntitiesForm;
}

export interface IndexContractsResearchesCreateResearchRequest {
  researchName?: string | null;
  researchPurpose?: string | null;
  questions?: string[] | null;
}

export interface IndexContractsResearchesCreateResearchResponse {
  research?: IndexDomainEntitiesResearch;
}

export interface IndexContractsResearchesUpdateResearchResponse {
  research?: IndexDomainEntitiesResearch;
}

export interface IndexContractsRespondentsListsGetRespondentsByListResponse {
  respondents?: IndexDomainEntitiesRespondent[] | null;
}

export interface IndexDomainEntitiesForm {
  id?: string | null;
  researchId?: string | null;
  respondentsListId?: string | null;
  /** @format int64 */
  formDate?: number;
  respondentsType?: IndexDomainEntitiesRespondentsListType;
  respondentsIds?: string[] | null;
  /** @format int32 */
  respondentsCount?: number;
  /** @format int32 */
  repliesCount?: number;
  answers?: Record<string, string[]>;
}

export interface IndexDomainEntitiesResearch {
  id?: string | null;
  name?: string | null;
  purpose?: string | null;
  createdBy?: string | null;
  status?: IndexDomainEntitiesStatus;
  /** @format int64 */
  createdAt?: number;
  /** @format int64 */
  startDate?: number;
  /** @format int64 */
  endDate?: number;
  /** @format double */
  overallIndex?: number;
  indexByRespondentsType?: Record<string, number>;
  respondentListIds?: string[] | null;
  questions?: string[] | null;
}

export interface IndexDomainEntitiesRespondent {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

export interface IndexDomainEntitiesRespondentsList {
  id?: string | null;
  name?: string | null;
  researchId?: string | null;
  type?: IndexDomainEntitiesRespondentsListType;
  /** @format int64 */
  createdAt?: number;
  /** @format int32 */
  respondentsCount?: number;
  /** @format int32 */
  phoneNumbersCount?: number;
  /** @format int32 */
  emailsCount?: number;
  respondentsId?: string[] | null;
  formsIds?: string[] | null;
}

export enum IndexDomainEntitiesRespondentsListType {
  Consumers = 'Consumers',
  Workers = 'Workers',
  Suppliers = 'Suppliers',
  Competitors = 'Competitors',
  Locals = 'Locals',
  Other = 'Other',
}

export enum IndexDomainEntitiesStatus {
  Active = 'Active',
  Planned = 'Planned',
  Finished = 'Finished',
}

export interface IndexDomainEntitiesUser {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
  email?: string | null;
  companyName?: string | null;
  /** @format int64 */
  createdAt?: number;
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface GetListsListParams {
  listsIds?: string[];
}
