import React from 'react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { CommonElementsProps } from '../types';

type InputProps = JSX.IntrinsicElements['input'] &
  CommonElementsProps & {
    name: string;
    control: Control<any>;
    title?: string;
    validationRegExp?: RegExp;
    errorCondition?: boolean;
  };

export function Input({
  scale = 'medium',
  title,
  className = '',
  disabled = false,
  name,
  control,
  ...rest
}: InputProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const isError = Boolean(error);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <div className={`text-primary-text ${className}`}>
          {title ? (
            <div className={`select-none  ${isError ? 'text-error' : 'text-secondary-text'}`}>
              {title}
            </div>
          ) : null}
          <input
            className={getInputStyles(scale, isError, disabled) + ' ' + className}
            disabled={disabled}
            {...rest}
            {...field}
          />
          <div className="h-4 text-error">
            {isError && error?.message ? String(error.message) : null}
          </div>
        </div>
      )}
    />
  );
}

function getInputStyles(scale: InputProps['scale'], isError: boolean, disabled: boolean) {
  let styles = 'duration-300 rounded focus-visible:outline-auto ';

  switch (scale) {
    case 'small':
      styles += 'px-2 py-1.5 text-sm ';
      break;
    case 'medium':
      styles += 'px-4 py-2.5 text-base ';
      break;
    case 'large':
      styles += 'px-6 py-3.5 text-lg font-semibold ';
      break;
  }

  if (!isError) {
    styles += 'border border-darkGray border-opacity-40 ';
  } else {
    styles += 'border border-error ';
  }

  if (disabled) styles += 'opacity-50 ';

  return styles;
}
