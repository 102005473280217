import React, { FC } from 'react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

type RatePickerProps = { title: string; name: string };

const ten = Array.from({ length: 10 });

export const RatePicker: FC<RatePickerProps> = ({ name, title }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const isError = Boolean(error);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <div className={`select-none mb-1  ${isError ? 'text-error' : 'text-secondary-text'}`}>
            {title}
          </div>
          <div className="flex flex-col gap-[5px]">
            {ten.map((_, i) => (
              <div key={i} className="flex gap-[20px]">
                <input
                  type="radio"
                  checked={field.value === i + 1}
                  onChange={() => field.onChange(i + 1)}
                />{' '}
                <div className={`${isError ? 'text-error' : ''}`}>{i + 1}</div>
              </div>
            ))}
          </div>
          <div className="h-4 text-error">
            {isError && error?.message ? String(error.message) : null}
          </div>
        </div>
      )}
    />
  );
};
