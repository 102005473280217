import React, { FC, useRef } from 'react';
import get from 'lodash/get';
import { Button } from '../../components/Button';
import { MdAdd, MdEdit } from 'react-icons/md';
import { Controller, useFormContext } from 'react-hook-form';

type FilePickerProps = { title: string; name: string };

export const FilePicker: FC<FilePickerProps> = ({ name, title }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const isError = Boolean(error);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <div className={`select-none mb-1  ${isError ? 'text-error' : 'text-secondary-text'}`}>
            {title}
          </div>
          <Button className='w-full' onClick={() => fileInputRef.current?.click()}>
            <div className="flex">
              {field.value ? <MdEdit size={24} /> : <MdAdd size={24} />}
              <span className="ml-2">{field.value ? 'Изменить' : 'Загрузить'} файл</span>
            </div>
          </Button>
          <div className="h-4 text-error">
            {isError && error?.message ? String(error.message) : null}
          </div>
          <input
            className="hidden"
            type="file"
            accept=".csv"
            onChange={(e) =>
              field.onChange(e.target.files && e.target.files[0] ? e.target.files[0] : undefined)
            }
            ref={fileInputRef}
          />
        </div>
      )}
    />
  );
};
