import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useGetRespondentsListsByIdsQuery } from '../../../api/queries/respondents';
import { Spinner } from '../../../components/Spinner';
import { Table } from '../../../components/Table';
import { respondentsListTypeMap } from '../../../modules/respondent-list/const';

type ResearchRespondentsListsTableProps = {
  respondentsListIds: string[];
};

export const ResearchRespondentsListsTable: FC<ResearchRespondentsListsTableProps> = ({
  respondentsListIds,
}) => {
  const { data, isLoading } = useGetRespondentsListsByIdsQuery(respondentsListIds);
  console.log(data);
  return (
    <div className="mt-4">
      {isLoading && <Spinner />}
      {data && data.respondentsLists && (
        <Table
          cols={['Название', 'Тип', 'Количество респондентов']}
          rows={data.respondentsLists.map((respondentsList) => [
            <Link
              className="text-decoration-line: underline text-link"
              to={`./respondentsList/${respondentsList.id}/`}
              key={respondentsList.id}
            >
              {respondentsList.name}
            </Link>,
            respondentsList.type ? respondentsListTypeMap[respondentsList.type] : '-',
            respondentsList.respondentsCount,
          ])}
          indexColAlign="left"
        />
      )}
    </div>
  );
};
