import { array, number, object, string } from 'yup';

export type FormValues = {
  answers: { question: string; answer: string }[];
};

export const validationSchema = object().shape({
  answers: array()
    .of(
      object().shape({
        question: string(),
        answer: number()
          .typeError('Введите ответ от 0 до 10')
          .min(0, 'Ответ не может быть меньше 0')
          .max(10, 'Ответ не может быть больше 10')
          .required('Заполните ответ'),
      }),
    )
    .required(),
});
