import React, { FC } from 'react';
import { useGetRespondentsByRespondentListIdQuery } from '../../../api/queries/respondents';
import { Spinner } from '../../../components/Spinner';
import { Table } from '../../../components/Table';

type RespondentsTableProps = {
  respondentsListId: string;
};

export const RespondentsTable: FC<RespondentsTableProps> = ({ respondentsListId }) => {
  const { data: respondents, isLoading } =
    useGetRespondentsByRespondentListIdQuery(respondentsListId);

  return (
    <div className="flex flex-wrap flex-col">
      {isLoading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      {respondents && (
        <Table
          cols={['Номер', 'Имя', 'Телефон', 'Email']}
          rows={respondents.respondents?.map((respondent, index) => [
            index + 1,
            respondent.name,
            respondent.phoneNumber,
            respondent.email,
          ])}
          indexColAlign="left"
        />
      )}
    </div>
  );
};
