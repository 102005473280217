import React from 'react';
import { formatUserName } from '../../lib';
import { useAuthStore } from '../auth';

export const UserInfo = () => {
  const userData = useAuthStore((store) => store.userData);
  return (
    <>
      {userData && (
        <div className="mb-4">
          <div>Пользователь: {formatUserName(userData)}</div>
          <div>Компания: {userData.companyName}</div>
        </div>
      )}
    </>
  );
};
