import { object, string } from 'yup';

export type FormValues = {
  email: string;
  password: string;
};

export const validationSchema = object().shape({
  email: string().required('Заполните поле').email('Неверный email'),
  password: string().required('Заполните поле'),
});
