import { array, object, string } from 'yup';
import { IndexDomainEntitiesResearch } from '../../../../api/generated/data-contracts';

export type FormValues = {
  researchName: string;
  researchPurpose: string;
  questions: { question: string }[];
};

export const validationSchema = object().shape({
  researchName: string().required('Заполните имя'),
  researchPurpose: string().required('Заполните цель'),
  questions: array()
    .of(
      object().shape({
        question: string().required('Заполните вопрос'),
      }),
    )
    .required(),
});

export const getDefaultValues = (research: IndexDomainEntitiesResearch): FormValues => {
  return {
    researchName: research.name ?? '',
    researchPurpose: research.purpose ?? '',
    questions: [...(research.questions ?? []).map((question) => ({ question }))],
  };
};
