import React, { useRef } from 'react';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { useLogin } from '../../modules/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues, validationSchema } from './form';
import { Input } from '../../components/Input';
import ReCAPTCHA from 'react-google-recaptcha';

export function Login() {
  const { login, isLoading } = useLogin();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { control, handleSubmit } = form;

  const onSubmit = handleSubmit(async ({ email, password }) => {
    const token = captchaRef.current?.getValue();
    if (!token) return;

    login({ email, password });
  });

  return (
    <div className="bg-main flex justify-center content-center items-center h-screen">
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <div className="bg-blue flex flex-col items-center px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[380px]">
            <Logo version="transparent" size={60} className="mb-[20px]" />
            <Heading className="self-start" size="S">
              Вход в Личный кабинет
            </Heading>
            <Input
              title="Почта"
              type="email"
              placeholder="Почта"
              className="w-full"
              name="email"
              control={control}
            />
            <Input
              title="Пароль"
              type="password"
              placeholder="Пароль"
              className="w-full mt-2"
              name="password"
              control={control}
            />
            <ReCAPTCHA sitekey='6LfaxAcnAAAAAAlWDPPcbCnNl5BMhHaKHIfrQCpq' ref={captchaRef}  />
            <Button type="submit" disabled={isLoading} className="w-full mt-4">
              Войти
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
