import React, { useState } from 'react';
import get from 'lodash/get';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useSearch } from '../../lib/hooks';
import { Button } from '../Button';
import { ControlledInput } from '../ControlledInput';

interface HasId {
  id?: string | undefined | null;
}

interface SelectProps<T extends HasId> {
  title: string;
  name: string;
  items: T[];
  itemToString: (item: T) => string;
  control: Control<any>;
  filterFunction: (searchString: string, item: T) => boolean;
  disabled?: boolean;
}

export function Select<T extends HasId>({
  title,
  items,
  name,
  itemToString,
  control,
  filterFunction,
  disabled,
}: SelectProps<T>) {
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const { filteredValue, searchInput, setSearchInput } = useSearch(items, filterFunction);

  const toggleFocus = () => setInputFocus(!inputFocus);

  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const isError = Boolean(error);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <ControlledInput
              title={title}
              errorMessage={String(error?.message)}
              isError={isError}
              className="w-full"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onFocus={toggleFocus}
              onBlur={toggleFocus}
              disabled={disabled}
            />
            {inputFocus ? (
              <div className="w-full z-20 flex flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
                {filteredValue?.length ? (
                  filteredValue.map((item) => (
                    <Button
                      key={item.id}
                      variant="no-bg"
                      scale="large"
                      className="text-main-text pl-6 rounded-xl text-left w-full"
                      onMouseDown={() => {
                        setSearchInput(itemToString(item));
                        field.onChange(item);
                      }}
                    >
                      {itemToString(item)}
                    </Button>
                  ))
                ) : (
                  <div className="px-6 py-2">Не найдено</div>
                )}
              </div>
            ) : null}
          </>
        )}
      />
    </>
  );
}
