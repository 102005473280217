import React, { FC } from 'react';
import { Table } from '../../../components/Table';

type ResearchQuestionsTableProps = {
  questions: string[];
};

export const ResearchQuestionsTable: FC<ResearchQuestionsTableProps> = ({ questions }) => {
  return (
    <div className="mt-4">
      <Table
        cols={['Номер вопроса', 'Вопрос']}
        rows={questions.map((question, i) => [i + 1, question])}
        indexColAlign="left"
      />
    </div>
  );
};
