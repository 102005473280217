/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IndexApplicationFormsCommandsSendAnswersSendAnswersCommand,
  IndexApplicationFormsQueriesGetFormGetFormResult,
  IndexApplicationFormsQueriesGetFormResultGetFormResultResponse,
  IndexContractsFormsCreateFormRequest,
  IndexContractsFormsCreateFormResponse,
  MicrosoftAspNetCoreMvcProblemDetails,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Forms<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Forms
   * @name CreateCreate
   * @summary Создание новой рассылки
   * @request POST:/forms/create
   * @secure
   */
  createCreate = (data: IndexContractsFormsCreateFormRequest, params: RequestParams = {}) =>
    this.request<IndexContractsFormsCreateFormResponse, MicrosoftAspNetCoreMvcProblemDetails>({
      path: `/forms/create`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Forms
   * @name GetForms
   * @summary Получение рассылки по токену
   * @request GET:/forms/get/{userToken}
   * @secure
   */
  getForms = (userToken: string, params: RequestParams = {}) =>
    this.request<
      IndexApplicationFormsQueriesGetFormGetFormResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/forms/get/${userToken}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Forms
   * @name SendAnswersCreate
   * @summary Отправка ответов по рассылке
   * @request POST:/forms/send-answers
   * @secure
   */
  sendAnswersCreate = (
    data: IndexApplicationFormsCommandsSendAnswersSendAnswersCommand,
    params: RequestParams = {},
  ) =>
    this.request<string, MicrosoftAspNetCoreMvcProblemDetails>({
      path: `/forms/send-answers`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Forms
   * @name GetFormResultsDetail
   * @summary Получение результатов рассылки
   * @request GET:/forms/get-form-results/{id}
   * @secure
   */
  getFormResultsDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      IndexApplicationFormsQueriesGetFormResultGetFormResultResponse,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/forms/get-form-results/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
