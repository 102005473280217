import { IndexDomainEntitiesRespondentsListType as RespondentsListType } from '../../../../api/generated/data-contracts';
import { respondentsListTypeMap } from '../../../../modules/respondent-list/const';

export type ListTypeVariant = {
  id: string;
  type: RespondentsListType;
};

export const typeVariants: ListTypeVariant[] = Object.values(RespondentsListType).map(
  (type, i) => ({
    id: String(i),
    type,
  }),
);

export const filterFunction = (searchString: string, item: ListTypeVariant): boolean =>
  respondentsListTypeMap[item.type].toLowerCase().includes(searchString.toLowerCase());
