import React from 'react';
import { Button } from '../../components/Button';
import { Logo } from '../../components/Logo';
import { logout } from '../auth';

export const Header = () => {
  return (
    <header className="z-10 sticky top-0 flex justify-between gap-12 bg-main py-5 px-24 select-none w-full">
      <div className="flex justify-center items-center gap-4">
        <Logo version="minimized" size={44} />
        <div className="text-white text-xl">
          Индекс этичности
        </div>
      </div>
      <Button onClick={logout}>Выйти</Button>
    </header>
  );
};
