import { number, object } from 'yup';

export type FormValues = {
  count: number;
};

export const validationSchema = object().shape({
  count: number().typeError('Введите число')
  .min(1, 'Введите число не меньше 1')
  .required('Заполните количество'),
});
