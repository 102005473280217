import React from 'react';
import { Link } from 'react-router-dom';
import { IndexDomainEntitiesResearch } from '../../../api/generated/data-contracts';
import { useGetUserResearchesQuery } from '../../../api/queries/researches';
import { ControlledInput } from '../../../components/ControlledInput';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { formatDate, useSearch } from '../../../lib';
import { formatResearchStatus } from '../helpers';

const searchFilterFunction = (searchString: string, research: IndexDomainEntitiesResearch) => {
  const search = searchString.toLowerCase();
  const name = research.name?.toLowerCase() || '';
  const purpose = research.purpose?.toLowerCase() || '';

  return (name.includes(search) || purpose.includes(search)) ?? false;
};

export function ResearchesListTable() {
  const { data } = useGetUserResearchesQuery();

  const {
    filteredValue: filteredResearches,
    searchInput,
    setSearchInput,
  } = useSearch(data?.researches ?? [], searchFilterFunction);

  return (
    <div>
      <ControlledInput
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по имени"
        disabled={!data?.researches?.length}
      />
      {filteredResearches.length ? (
        <Table
          cols={[
            'Название',
            'Статус',
            'Дата создания',
            'Количество вопросов',
          ]}
          rows={filteredResearches.map((research) => {
            return [
              <Link
                className="text-decoration-line: underline text-link"
                to={`./research/${research.id}/`}
                key={research.id}
              >
                {research.name}
              </Link>,
              research.status ? formatResearchStatus(research.status) : '',
              formatDate(research.createdAt),
              research.questions?.length ?? 0,
            ];
          })}
          indexColAlign="left"
        />
      ) : (
        <Heading transparent size="S">
          Нет исследований
        </Heading>
      )}
    </div>
  );
}
