import React, { FC } from 'react';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { Table } from '../../../../components/Table';

interface InstructionModalProps {
  modalOpened: boolean;
  onClose: () => void;
}

export const InstructionModal: FC<InstructionModalProps> = ({ modalOpened, onClose }) => {
  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <div className="font-semibold text-3xl mb-4">Инстуркция по форматированию файла</div>
          <div className='mb-2'>
            Для загрузки списка респондентов нужен файл в формате CSV. Первая строка всегда
            заполняется одинаково - это перечисление полей (Имя,Email,Телефон), последующие строки
            должны содеражть данные о респондентах.
          </div>
          <div className='mb-2'>Пример заполнения:</div>
          <Table
            cols={['Имя,Email,Телефон']}
            rows={[
              ['Иванов Иван Иванович,email1@email.com,+79001112233'],
              ['Сергеев Сергей Сергеевич,email2@email.com,+79004445566'],
              ['Евгеньев Евгений Евгеньевич,email3@email.com,+79007778899'],
            ]}
          />
          <div className="mt-8 flex justify-end gap-2">
            <Button onClick={onClose}>Ок</Button>
          </div>
        </div>
      }
    />
  );
};
