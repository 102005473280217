import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormsController } from '../controllers';
import {
  IndexApplicationFormsCommandsSendAnswersSendAnswersCommand,
  IndexApplicationFormsQueriesGetFormResultGetFormResultResponse,
  IndexContractsFormsCreateFormRequest,
} from '../generated/data-contracts';
import { RESPONDENTS_PREFIX } from './respondents';

const FORMS_PREFIX = 'FORMS_PREFIX';

export function useGetFormResultsByIdQuery(formId: string | undefined) {
  return useQuery(
    [FORMS_PREFIX, 'byId', formId],
    () => FormsController.getFormResultsDetail(formId ?? ''),
    { enabled: !!formId },
  );
}

export function useGetMultipleFormsResultsByIdsQuery(formIds: string[] | undefined) {
  const promisesArray: Array<
    Promise<IndexApplicationFormsQueriesGetFormResultGetFormResultResponse>
  > = [];
  for (const id of formIds ?? []) {
    promisesArray.push(FormsController.getFormResultsDetail(id));
  }
  return useQuery([FORMS_PREFIX, 'byIds', formIds], () => Promise.all(promisesArray), {
    enabled: !!formIds,
  });
}

export function useGetFormByUserTokenQuery(token: string) {
  return useQuery([FORMS_PREFIX, token], () => FormsController.getForms(token));
}

export function useSendFormAnswersMutation() {
  return useMutation(
    [FORMS_PREFIX, 'sendAnswers'],
    (params: IndexApplicationFormsCommandsSendAnswersSendAnswersCommand) =>
      FormsController.sendAnswersCreate(params),
  );
}

export function useCreateFormMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    [FORMS_PREFIX, 'createForm'],
    (params: IndexContractsFormsCreateFormRequest) => FormsController.createCreate(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(FORMS_PREFIX);
        void queryClient.invalidateQueries(RESPONDENTS_PREFIX);
      },
    },
  );
}
