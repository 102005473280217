/* eslint-disable complexity */
import { useCallback } from 'react';
import { useAuthorizeMutation } from '../../../api/queries/authorization';
import { useAuthStore } from '../store';

type LoginData = {
  email: string;
  password: string;
};

export const useLogin = () => {
  const setData = useAuthStore((store) => store.setData);

  const { mutateAsync, isLoading } = useAuthorizeMutation();

  const login = useCallback(
    async (data: LoginData) => {
      const { token, user } = await mutateAsync(data);
      setData({
        token: token ?? '',
        userData: {
          companyName: user?.companyName ?? '',
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          patronymic: user?.patronymic ?? '',
          email: user?.email ?? '',
          id: user?.id ?? '',
        },
      });
    },
    [mutateAsync, isLoading],
  );

  return { login, isLoading };
};
