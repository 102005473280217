import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues, validationSchema } from './form';
import { useLocation } from 'react-router-dom';
import { useGetFormByUserTokenQuery, useSendFormAnswersMutation } from '../../api/queries/forms';
import { Spinner } from '../../components/Spinner';
import { RatePicker } from '../../components/RatePicker/RatePicker';

export function FormPage() {
  const [answersSent, setAnswersSent] = useState(false);

  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search).get('token');

  const { data, isLoading: isLoadingForm, error } = useGetFormByUserTokenQuery(paramsToken ?? '');

  console.log(data, isLoadingForm, error)

  const { isLoading: isSendingAnswers, mutateAsync: sendAnswers } = useSendFormAnswersMutation();

  const form = useForm<FormValues>({
    defaultValues: { answers: [] },
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, handleSubmit } = form;
  const { fields } = useFieldArray({ control, name: 'answers' });

  useEffect(() => {
    if (data?.researchQuestions) {
      reset({ answers: data.researchQuestions.map((question) => ({ question })) });
    }
  }, [data]);

  const onSubmit = handleSubmit(async ({ answers }) => {
    console.log(paramsToken);
    console.log(answers);
    await sendAnswers({
      token: paramsToken,
      answers: answers.map((answer) => String(answer.answer)),
    });
    setAnswersSent(true);
  });

  return (
    <div className="bg-main flex justify-center content-center h-screen w-full">
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <div className="bg-blue flex flex-col items-center mt-[2.5vh] px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[95vw]">
            {answersSent || (!isLoadingForm && !data) ? (
              <Heading className="mb-0">Спасибо за участие в опросе!</Heading>
            ) : (
              <>
                {isLoadingForm && <Spinner />}
                {data && (
                  <>
                    <Heading className="self-start mb-0" size="S">
                      Исследование &quot;{data.researchName}&quot;
                    </Heading>
                    <div className="self-start mb-2">
                      Цель исследования: &quot;{data.researchPurpose}&quot;
                    </div>
                    {fields.map((field, i) => (
                      <div key={field.id} className="flex w-full gap-[20px] mb-2">
                        <RatePicker title={field.question} name={`answers.${i}.answer`} />
                      </div>
                    ))}
                  </>
                )}
                <Button type="submit" disabled={isSendingAnswers} className="w-full mt-4">
                  Отправить ответы
                </Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
