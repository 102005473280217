/* eslint-disable complexity */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetFormResultsByIdQuery } from '../../api/queries/forms';
import { Spinner } from '../../components/Spinner';
import { Table } from '../../components/Table';
import { formatDateWithTime, pluralize } from '../../lib';
import { averageOfArray, calculateAverage } from './helpers';

export const FormResults = () => {
  const { formId } = useParams();

  const { data, isLoading } = useGetFormResultsByIdQuery(formId);

  const unanswered =
    data && data.respondentsCount ? data.respondentsCount - (data.repliesCount ?? 0) : 0;

  const tableCols = [
    'Вопрос',
    ...Array.from({ length: data?.respondentsCount ?? 0 }).map((_, i) => String(i + 1)),
    'Среднее',
  ];

  const averages = calculateAverage(data?.answers ?? []);

  const tableRows = [
    ...(data?.questions ?? []).map((question, i) => [
      question,
      ...(data?.answers ?? []).map((answer) => String(answer[i])),
      ...Array.from({ length: unanswered }).map(() => '-'),
      averages[i] ?? '-',
    ]),
    [
      'Среднее',
      ...(data?.answers ?? []).map((answers) => averageOfArray(answers)),
      ...Array.from({ length: unanswered }).map(() => '-'),
      '',
    ],
  ];

  return (
    <div className="flex flex-wrap flex-col">
      {isLoading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      {data && (
        <>
          <div className="font-semibold text-3xl">
            Рассылка по списку респондентов &quot;{data.respondentsListName}&quot; для исследования{' '}
            &quot;{data.researchName}&quot;
          </div>
          <div className='mt-4'>Дата рассылки: {formatDateWithTime(data.formDate)}</div>
          <div>
            {pluralize(data.repliesCount ?? 0, ['Ответитл', 'Ответили', 'Ответили'])}{' '}
            {data.repliesCount ?? 0} из {data.respondentsCount}{' '}
            {pluralize(data.respondentsCount ?? 0, ['респондента', 'респондентов', 'респондентов'])}
          </div>
          <Table className="mt-4" cols={tableCols} rows={tableRows} />
        </>
      )}
    </div>
  );
};
