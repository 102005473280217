import React from 'react';
import { Button } from '../../../components/Button';
import { useModal } from '../../../lib';
import { EditResearchModal } from '../components';
import { ResearchesListTable } from './ResearchesListTable';

export const ResearchesList = () => {
  const { modalOpened, openModal, closeModal } = useModal();
  return (
    <div className="flex flex-wrap flex-col">
      <div className="font-semibold text-3xl">Исследования</div>
      <Button className="w-[300px] mt-4 mb-4 " onClick={openModal}>
        Добавить исследование
      </Button>
      <ResearchesListTable />
      <EditResearchModal modalOpened={modalOpened} onClose={closeModal} />
    </div>
  );
};
