/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IndexApplicationResearchesCommandsCloseResearchCloseResearchResult,
  IndexApplicationResearchesQueriesGetUserResearchesGetResearchResult,
  IndexApplicationResearchesQueriesGetUserResearchesGetUserResearchesResult,
  IndexContractsResearchesCreateResearchRequest,
  IndexContractsResearchesCreateResearchResponse,
  IndexContractsResearchesUpdateResearchResponse,
  IndexDomainEntitiesResearch,
  MicrosoftAspNetCoreMvcProblemDetails,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Researches<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Research
   * @name GetUserResearchesList
   * @summary Получение исследований пользователя
   * @request GET:/researches/get-user-researches
   * @secure
   */
  getUserResearchesList = (params: RequestParams = {}) =>
    this.request<
      IndexApplicationResearchesQueriesGetUserResearchesGetUserResearchesResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/researches/get-user-researches`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Research
   * @name GetResearchDetail
   * @summary Получение исследования по ID
   * @request GET:/researches/get-research/{id}
   * @secure
   */
  getResearchDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      IndexApplicationResearchesQueriesGetUserResearchesGetResearchResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/researches/get-research/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Research
   * @name CreateResearchCreate
   * @summary Создание нового исследования
   * @request POST:/researches/create-research
   * @secure
   */
  createResearchCreate = (
    data: IndexContractsResearchesCreateResearchRequest,
    params: RequestParams = {},
  ) =>
    this.request<
      IndexContractsResearchesCreateResearchResponse,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/researches/create-research`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Research
   * @name UpdateResearchCreate
   * @summary Обновление исследования
   * @request POST:/researches/update-research
   * @secure
   */
  updateResearchCreate = (data: IndexDomainEntitiesResearch, params: RequestParams = {}) =>
    this.request<
      IndexContractsResearchesUpdateResearchResponse,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/researches/update-research`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Research
   * @name CloseResearchDetail
   * @summary Завершение исследования
   * @request GET:/researches/close-research/{id}
   * @secure
   */
  closeResearchDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      IndexApplicationResearchesCommandsCloseResearchCloseResearchResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/researches/close-research/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
