import React, { FC } from 'react';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues, validationSchema } from './form';
import { useCreateFormMutation } from '../../../../api/queries/forms';

interface CreateFormModalProps {
  researchId: string;
  respondentsListId: string;
  modalOpened: boolean;
  onClose: () => void;
}

export const CreateFormModal: FC<CreateFormModalProps> = ({
  researchId,
  respondentsListId,
  modalOpened,
  onClose,
}) => {
  const { mutateAsync, isLoading } = useCreateFormMutation();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, handleSubmit } = form;

  const onSubmit = handleSubmit(async ({ count }) => {
    await mutateAsync({
      researchId,
      respondentsListId,
      respondentsCount: count,
    });
    reset();
    onClose();
  });

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <>
          <div>
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <div className="font-semibold text-3xl mb-4">Создание рассылки</div>
                <div className="mb-2">
                  <Input
                    title="Количество респондентов из списка"
                    className="w-full"
                    name="count"
                    control={control}
                  />
                </div>

                <div className="mt-8 flex justify-end gap-2">
                  <Button onClick={onClose}>Отменить</Button>
                  <Button type="submit" disabled={isLoading}>
                    Начать рассылку
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </>
      }
    />
  );
};
