import { IndexDomainEntitiesRespondentsListType as RespondentsListType } from "../../api/generated/data-contracts";

export const respondentsListTypeMap: Record<RespondentsListType, string> = {
  [RespondentsListType.Competitors]: 'Конкуренты',
  [RespondentsListType.Consumers]: 'Потребители',
  [RespondentsListType.Locals]: 'Местное сообщество',
  [RespondentsListType.Other]: 'Иное',
  [RespondentsListType.Suppliers]: 'Поставщики',
  [RespondentsListType.Workers]: 'Работники',
};
