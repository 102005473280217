import { create } from 'zustand';

type Notification = {
  message: string;
};

type NotificationStore = {
  notification: Notification | null;
  deleteNotification: () => void;
};

export const useNotificationStore = create<NotificationStore>((set) => ({
  notification: null,
  deleteNotification: () =>
    set(() => ({
      notification: null,
    })),
}));
