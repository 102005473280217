import React, { FC, ReactNode } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Header } from './Header';
import { UserInfo } from './UserInfo';

type MainTemplateProps = {
  children: ReactNode;
};

export const MainTemplate: FC<MainTemplateProps> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <main className="w-full h-full">
      <Header />
      <div className="py-5 px-24">
        {pathname !== '/' && (
          <Button variant="no-bg" onClick={goBack}>
            <div className="flex items-center text-link">
              <MdArrowBack size={24} className="mr-2" />
              Назад
            </div>
          </Button>
        )}
        <UserInfo />
        {children}
      </div>
    </main>
  );
};
