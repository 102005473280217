/* eslint-disable complexity */
import React from 'react';
import { useParams } from 'react-router-dom';
import { IndexDomainEntitiesStatus } from '../../../api/generated/data-contracts';
import {
  useCloseResearchMutation,
  useGetResearchByIdQuery,
  useUpdateResearchMutation,
} from '../../../api/queries/researches';
import { Button } from '../../../components/Button';
import { Spinner } from '../../../components/Spinner';
import { formatDate, useModal } from '../../../lib';
import { CreateRespondentsListModal, EditResearchModal } from '../components';
import { formatResearchStatus } from '../helpers';
import { ResearchQuestionsTable } from './ResearchQuestionsTable';
import { ResearchRespondentsListsTable } from './ResearchRespondentsListsTable';

export const Research = () => {
  const { researchId } = useParams();

  const { data, isLoading } = useGetResearchByIdQuery(researchId ?? '');

  const { mutateAsync: updateResearch, isLoading: updatingResearch } = useUpdateResearchMutation();
  const { mutateAsync: closeResearch, isLoading: closingResearch } = useCloseResearchMutation(
    researchId ?? '',
  );

  const handleStartResearch = () => {
    if (data?.research) {
      updateResearch({ ...data.research, status: IndexDomainEntitiesStatus.Active });
    }
  };

  const handleCloseResearch = () => {
    closeResearch();
  };

  const {
    modalOpened: editResearchModalOpened,
    openModal: openEditResearchModal,
    closeModal: closeEditResearchModal,
  } = useModal();

  const {
    modalOpened: createRespondentsListModalOpened,
    openModal: openCreateRespondentsListModal,
    closeModal: closeCreateRespondentsListModal,
  } = useModal();

  return (
    <div className="flex flex-wrap flex-col">
      {isLoading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      {data && data.research && (
        <>
          <div className="font-semibold text-3xl">
            Исследование &quot;{data.research?.name}&quot;
          </div>
          <div className="flex gap-[20px]">
            {data.research.status !== IndexDomainEntitiesStatus.Finished && (
              <Button className="w-[300px] mt-4 mb-4 " onClick={openEditResearchModal}>
                Редактировать исследование
              </Button>
            )}
            {data.research.status === IndexDomainEntitiesStatus.Planned && (
              <Button
                className="w-[300px] mt-4 mb-4"
                onClick={handleStartResearch}
                disabled={updatingResearch}
              >
                Начать исследование
              </Button>
            )}
            {data.research.status === IndexDomainEntitiesStatus.Active && (
              <Button
                className="w-[300px] mt-4 mb-4 "
                onClick={handleCloseResearch}
                disabled={closingResearch}
              >
                Завершить исследование
              </Button>
            )}
          </div>
          <div>Цель исследования: {data.research.purpose}</div>
          <div>
            Статус иследования:{' '}
            {data.research.status ? formatResearchStatus(data.research.status) : '-'}
          </div>
          <div>Дата создания: {formatDate(data.research.createdAt)}</div>
          <div>Дата завершения: {formatDate(data.research.endDate)}</div>
          <div className="font-semibold text-3xl mt-4">Вопросы</div>
          <ResearchQuestionsTable questions={data.research.questions ?? []} />
          <div className="font-semibold text-3xl mt-4">Списки респондентов</div>
          {data.research.status !== IndexDomainEntitiesStatus.Finished && (
            <Button className="w-[300px] mt-4 mb-4 " onClick={openCreateRespondentsListModal}>
              Добавить список респондентов
            </Button>
          )}
          {data.research.respondentListIds?.length === 0 && (
            <div className="mt-2 text-secondary-text">Списки респондентов не добавлены</div>
          )}
          <ResearchRespondentsListsTable
            respondentsListIds={data.research.respondentListIds ?? []}
          />
          <EditResearchModal
            researchId={researchId ?? ''}
            modalOpened={editResearchModalOpened}
            onClose={closeEditResearchModal}
          />
          <CreateRespondentsListModal
            researchId={researchId ?? ''}
            modalOpened={createRespondentsListModalOpened}
            onClose={closeCreateRespondentsListModal}
          />
        </>
      )}
    </div>
  );
};
