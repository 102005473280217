import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ResearchesController } from '../controllers';
import {
  IndexContractsResearchesCreateResearchRequest,
  IndexDomainEntitiesResearch,
} from '../generated/data-contracts';

export const RESEARCH_PREFIX = 'RESEARCH_PREFIX';

export function useGetUserResearchesQuery() {
  return useQuery([RESEARCH_PREFIX, 'userResearches'], () =>
    ResearchesController.getUserResearchesList(),
  );
}

export function useGetResearchByIdQuery(id: string | undefined) {
  return useQuery(
    [RESEARCH_PREFIX, 'research', id],
    () => ResearchesController.getResearchDetail(id ?? ''),
    { enabled: Boolean(id) },
  );
}

export function useCreateResearchMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    [RESEARCH_PREFIX, 'create'],
    (params: IndexContractsResearchesCreateResearchRequest) =>
      ResearchesController.createResearchCreate(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(RESEARCH_PREFIX);
      },
    },
  );
}

export function useUpdateResearchMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    [RESEARCH_PREFIX, 'update'],
    (params: IndexDomainEntitiesResearch) => ResearchesController.updateResearchCreate(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(RESEARCH_PREFIX);
      },
    },
  );
}

export function useCloseResearchMutation(id: string) {
  const queryClient = useQueryClient();
  return useMutation(
    [RESEARCH_PREFIX, 'close', id],
    () => ResearchesController.closeResearchDetail(id),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(RESEARCH_PREFIX);
      },
    },
  );
}
