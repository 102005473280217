import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from '../../pages/Login';
import { MainTemplate } from '../page-templates';
import { useAuthStore } from '../auth';
import { Research, ResearchesList } from '../../pages/Researches';
import { RespondentsList } from '../../pages/RespondentsLists/RespondentsList/RespondentsList';
import { FormPage } from '../../pages/FormPage/FormPage';
import { FormResults } from '../../pages/FormResults/FormResults';

const publicRoutes = [
  { path: '/', name: 'Логин', element: <Login /> },
  { path: '/form', name: 'Форма', element: <FormPage /> },
  {path: '*', name: '', element: <Navigate to="/" replace />},
];

const privateRoutes = [
  { path: '/', exact: true, name: 'Домашняя страница', element: <ResearchesList /> },
  { path: '/research/:researchId', name: 'Исследование', element: <Research /> },
  {
    path: '/research/:researchId/respondentsList/:respondentsListId',
    name: 'Список респондентов',
    element: <RespondentsList />,
  },
  {
    path: '/research/:researchId/respondentsList/:respondentsListId/formResults/:formId',
    name: 'Результаты рассылки',
    element: <FormResults />,
  },
];

export function Router() {
  const isAuth = useAuthStore((store) => store.token);
  return (
    <Routes>
      {isAuth
        ? privateRoutes.map(({ element, ...rest }) => {
            return (
              <Route key={rest.path} element={<MainTemplate>{element}</MainTemplate>} {...rest} />
            );
          })
        : publicRoutes.map(({ element, ...rest }) => {
            return <Route key={rest.path} element={element} {...rest} />;
          })}
    </Routes>
  );
}
