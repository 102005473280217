import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  email: string;
  companyName: string;
};

type AuthStore = {
  token: string | null;
  userData: UserData | null;
  setData: (data: { token: string; userData: UserData }) => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      token: null,
      userData: null,
      setData: ({ token, userData }) => set({ token, userData }),
    }),
    {
      name: 'auth-store',
    },
  ),
);
