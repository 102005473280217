/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IndexApplicationAuthenticationCommonAuthorizationResult,
  IndexContractsAuthorizationAuthorizeRequest,
  IndexContractsAuthorizationRegisterRequest,
  MicrosoftAspNetCoreMvcProblemDetails,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Authorization<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Authorization
   * @name AuthorizeCreate
   * @summary Авторизация пользователя
   * @request POST:/authorization/authorize
   * @secure
   */
  authorizeCreate = (
    data: IndexContractsAuthorizationAuthorizeRequest,
    params: RequestParams = {},
  ) =>
    this.request<
      IndexApplicationAuthenticationCommonAuthorizationResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/authorization/authorize`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Authorization
   * @name RegisterCreate
   * @summary Регистрация пользователя
   * @request POST:/authorization/register
   * @secure
   */
  registerCreate = (data: IndexContractsAuthorizationRegisterRequest, params: RequestParams = {}) =>
    this.request<
      IndexApplicationAuthenticationCommonAuthorizationResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/authorization/register`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
