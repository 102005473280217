import React, { FC } from 'react';
import { useNotificationStore } from './store';

type NotificationsProviderProps = {
  children?: React.ReactNode;
};

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const notification = useNotificationStore((store) => store.notification);
  const deleteNotification = useNotificationStore((store) => store.deleteNotification);
  return (
    <>
      {children}{' '}
      {notification && (
        <div
          onClick={deleteNotification}
          className={`absolute right-[20px] bottom-[20px] max-w-[300px] bg-error text-white p-[15px] rounded-[5px] cursor-pointer`}
        >
          {notification.message}
        </div>
      )}
    </>
  );
};
