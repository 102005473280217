import { useMutation } from 'react-query';
import { AuthorizationController } from '../controllers';
import { IndexContractsAuthorizationAuthorizeRequest } from '../generated/data-contracts';

const AUTH_PREFIX = 'AUTH_PREFIX';

export function useAuthorizeMutation() {
  return useMutation(
    [AUTH_PREFIX, 'authorize'],
    (params: IndexContractsAuthorizationAuthorizeRequest) =>
      AuthorizationController.authorizeCreate(params),
  );
}
