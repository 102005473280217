import { logout } from '../modules/auth';
import { useAuthStore } from '../modules/auth/store';
import { ApiConfig, HttpClient } from './generated/http-client';
import { Authorization } from './generated/Authorization';
import { Researches } from './generated/Researches';
import { Respondents } from './generated/Respondents';
import { Forms } from './generated/Forms';
import { createNotification } from '../modules/notifications';

const configuration: ApiConfig = {
  baseURL: 'https://service-index.ethics.hse.ru',
};

const withAuthorization = <T extends HttpClient>(controller: T) => {
  controller.instance.interceptors.request.use((config) => {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  controller.instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error)
      if (error.response.status === 401) {
        logout();
      } else {
        createNotification(error.response.data.title as string)
      }
      return error;
    },
  );
  return controller;
};

export const AuthorizationController = new Authorization(configuration);
export const FormsController = withAuthorization(new Forms(configuration));
export const ResearchesController = withAuthorization(new Researches(configuration));
export const RespondentsController = withAuthorization(new Respondents(configuration));
