/* eslint-disable complexity */
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IndexDomainEntitiesStatus } from '../../../api/generated/data-contracts';
import { useGetResearchByIdQuery } from '../../../api/queries/researches';
import { useGetRespondentsListsByIdsQuery } from '../../../api/queries/respondents';
import { Button } from '../../../components/Button';
import { Spinner } from '../../../components/Spinner';
import { Table } from '../../../components/Table';
import { useModal } from '../../../lib';
import { respondentsListTypeMap } from '../../../modules/respondent-list/const';
import { CreateFormModal } from '../components/CreateFormModal/CreateFormModal';
import { RespondentsTable } from './RespondentsTable';

export const RespondentsList = () => {
  const { researchId, respondentsListId } = useParams();

  const { modalOpened, openModal, closeModal } = useModal();

  const { data: respondentsLists, isLoading: isLoadingRespondentsList } =
    useGetRespondentsListsByIdsQuery([respondentsListId ?? '']);

  const respondentsList = (respondentsLists?.respondentsLists ?? [])[0];

  const { data: research, isLoading: isLoadingResearch } = useGetResearchByIdQuery(researchId);

  const isLoading = isLoadingRespondentsList || isLoadingResearch;

  return (
    <div className="flex flex-wrap flex-col">
      {isLoading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      {respondentsListId && respondentsList && research && (
        <>
          <div className="font-semibold text-3xl">
            Список респондентов &quot;{respondentsList?.name}&quot;
          </div>
          <div className=" mt-2">
            Тип списка: &quot;
            {respondentsList?.type ? respondentsListTypeMap[respondentsList.type] : ''}&quot;
          </div>
          {research.research?.status === IndexDomainEntitiesStatus.Active && (
            <Button className="w-[300px] mt-4 mb-4 " onClick={openModal}>
              Начать рассылку
            </Button>
          )}
          <div className="font-semibold text-3xl mt-4 mb-4">Рассылки по списку респондентов</div>
          {respondentsList.formsIds && respondentsList.formsIds.length > 0 ? (
            <Table
              cols={['Номер рассылки']}
              indexColAlign="left"
              rows={respondentsList.formsIds.map((formId, i) => [
                <Link
                  className="text-decoration-line: underline text-link"
                  to={`./formResults/${formId}/`}
                  key={formId}
                >
                  Рассылка {i + 1}
                </Link>,
              ])}
            />
          ) : (
            <div className="text-secondary-text">Ещё не создано ни одной рассылки</div>
          )}
          <div className="font-semibold text-3xl mt-4">Респонденты</div>
          <div className="mt-4">
            <RespondentsTable respondentsListId={respondentsListId} />
          </div>
        </>
      )}
      {researchId && respondentsListId && (
        <CreateFormModal
          modalOpened={modalOpened}
          onClose={closeModal}
          researchId={researchId}
          respondentsListId={respondentsListId}
        />
      )}
    </div>
  );
};
