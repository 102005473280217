/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetListsListParams,
  IndexApplicationRespondentsListsCommandsCreateRespondentsListCreateRespondentsListResult,
  IndexApplicationRespondentsListsQueriesGetRespondentsListsGetRespondentsListsResult,
  IndexContractsRespondentsListsGetRespondentsByListResponse,
  IndexDomainEntitiesRespondentsListType,
  MicrosoftAspNetCoreMvcProblemDetails,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Respondents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Respondents
   * @name CreateListCreate
   * @summary Создание нового списка респондентов
   * @request POST:/respondents/create-list
   * @secure
   */
  createListCreate = (
    data: {
      Name?: string;
      ResearchId?: string;
      Type?: IndexDomainEntitiesRespondentsListType;
      /** @format binary */
      ListFile?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      IndexApplicationRespondentsListsCommandsCreateRespondentsListCreateRespondentsListResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/respondents/create-list`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Respondents
   * @name GetListsList
   * @summary Получение списка респондентов
   * @request GET:/respondents/get-lists
   * @secure
   */
  getListsList = (query: GetListsListParams, params: RequestParams = {}) =>
    this.request<
      IndexApplicationRespondentsListsQueriesGetRespondentsListsGetRespondentsListsResult,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/respondents/get-lists`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Respondents
   * @name GetRespondentsByListDetail
   * @summary Получение респондентов по списку
   * @request GET:/respondents/get-respondents-by-list/{id}
   * @secure
   */
  getRespondentsByListDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      IndexContractsRespondentsListsGetRespondentsByListResponse,
      MicrosoftAspNetCoreMvcProblemDetails
    >({
      path: `/respondents/get-respondents-by-list/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
