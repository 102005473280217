/* eslint-disable complexity */
import React, { FC } from 'react';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import {
  useCreateResearchMutation,
  useGetResearchByIdQuery,
  useUpdateResearchMutation,
} from '../../../../api/queries/researches';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues, getDefaultValues, validationSchema } from './form';
import { IndexDomainEntitiesStatus } from '../../../../api/generated/data-contracts';

interface EditResearchModalProps {
  researchId?: string;
  modalOpened: boolean;
  onClose: () => void;
}

export const EditResearchModal: FC<EditResearchModalProps> = ({
  researchId,
  modalOpened,
  onClose,
}) => {
  const { data, isLoading: isLoadingResearch } = useGetResearchByIdQuery(researchId ?? '');

  const { mutateAsync: createResearch, isLoading: creatingResearch } = useCreateResearchMutation();

  const { mutateAsync: updateResearch, isLoading: updatingResearch } = useUpdateResearchMutation();

  const isLoading = isLoadingResearch || creatingResearch || updatingResearch;

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: data?.research ? getDefaultValues(data.research) : undefined,
  });
  const { control, reset, handleSubmit } = form;
  const {
    fields: questionsFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({ control, name: 'questions' });

  const onSubmit = handleSubmit(async ({ researchName, researchPurpose, questions }) => {
    if (researchId && data?.research) {
      await updateResearch({
        ...data.research,
        name: researchName,
        purpose: researchPurpose,
        questions: questions.map((field) => field.question),
      });
    } else {
      await createResearch({
        researchName,
        researchPurpose,
        questions: questions.map((field) => field.question),
      });
      reset();
    }
    onClose();
  });

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <div className="font-semibold text-3xl mb-4">
                {researchId ? 'Редактирование' : 'Создание'} исследования
              </div>
              <div className="mb-2">
                <Input
                  title="Название исследования"
                  className="w-full"
                  name="researchName"
                  control={control}
                />
              </div>
              <div className="mb-2">
                <Textarea
                  title="Цель исследования"
                  className="w-full h-40"
                  name="researchPurpose"
                  control={control}
                />
              </div>
              {(!researchId || data?.research?.status === IndexDomainEntitiesStatus.Planned) && (
                <>
                  {questionsFields.map((field, i) => (
                    <div key={field.id} className="flex w-full gap-[20px] mb-2">
                      <Input
                        title={`Вопрос ${i + 1}`}
                        className="w-full"
                        name={`questions.${i}.question`}
                        control={control}
                      />
                      <Button
                        type="button"
                        className="h-[46px] mt-[24px]"
                        onClick={() => removeQuestion(i)}
                      >
                        <MdDelete size={24} />
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    className="flex w-full"
                    onClick={() => appendQuestion({ question: '' })}
                  >
                    <MdAdd size={24} /> Добавить вопрос
                  </Button>
                </>
              )}
              <div className="mt-8 flex justify-end gap-2">
                <Button type="button" onClick={onClose}>
                  Отменить
                </Button>
                <Button disabled={isLoading} type="submit">
                  {researchId ? 'Обновить' : 'Создать'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      }
    />
  );
};
