export const calculateAverage = (answers: string[][]) => {
  const respondentsCount = answers.length;
  const hash: Record<number, number> = {};

  for (const array of answers) {
    for (let i = 0; i < array.length; i++) {
      hash[i] = hash[i] ? hash[i] + Number(array[i]) : Number(array[i]);
    }
  }

  return Object.values(hash).map((sum) => (sum / respondentsCount).toFixed(1));
};

export const averageOfArray = (answers: string[]) => {
  const sum = answers.reduce((acc, value) => acc + Number(value), 0);
  return (sum / answers.length).toFixed(1);
};
