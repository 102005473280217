import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RespondentsController } from '../controllers';
import { RESEARCH_PREFIX } from './researches';

export const RESPONDENTS_PREFIX = 'RESPONDENTS_PREFIX';

export function useGetRespondentsListsByIdsQuery(listsIds: string[]) {
  return useQuery([RESPONDENTS_PREFIX, 'respondentsLists', listsIds], () =>
    RespondentsController.getListsList({ listsIds }, { paramsSerializer: { indexes: null } }),
  );
}

export function useGetRespondentsByRespondentListIdQuery(id: string) {
  return useQuery([RESPONDENTS_PREFIX, 'respondents', id], () =>
    RespondentsController.getRespondentsByListDetail(id),
  );
}

export function useCreateRespondentsListMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    [RESPONDENTS_PREFIX, 'createList'],
    (params: Parameters<typeof RespondentsController.createListCreate>[0]) =>
      RespondentsController.createListCreate(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(RESPONDENTS_PREFIX);
        void queryClient.invalidateQueries(RESEARCH_PREFIX);
      },
    },
  );
}
